import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/CreateTask.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true,
      requiresSuperuser: true
    }
  },
  {
    path: "/create-photocollection",
    name: "create-photocollection",
    component: () => import("../views/CreatePhotoCollection.vue"),
    meta: {
      requiresAuth: true,
      requiresSuperuser: true
    }
  },
  {
    path: "/annotate",
    name: "annotate",
    component: () => import("../views/Annotate.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/classify",
    name: "classify",
    component: () => import("../views/Classify.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/tasks",
    name: "tasks",
    component: () => import("../views/Tasks.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/export",
    name: "export",
    component: () => import("../views/Export.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/photo-collections",
    name: "photo-collections",
    component: () => import("../views/PhotoCollections.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/categories",
    name: "categories",
    component: () => import("../views/Categories.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/questions",
    name: "questions",
    component: () => import("../views/Questions.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      requiresLogged: true
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Logout.vue"),
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
