











import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    NavBar: () => import("@/components/Navbar.vue")
  }
})
export default class App extends Vue {}
