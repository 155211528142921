import Vue from "vue";
import Vuex from "vuex";
import { axiosBase } from "@/api/axios-base";
import { userService } from "@/services/user.service";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    accessToken: localStorage.getItem("access_token") || null,
    refreshToken: localStorage.getItem("refresh_token") || null,
    isSuperuser: localStorage.getItem("is_superuser") || false
  },
  getters: {
    loggedIn(state) {
      return state.accessToken != null;
    },
    isSuperuser(state) {
      if (typeof state.isSuperuser === "string") {
        return state.isSuperuser === "true";
      } else {
        return state.isSuperuser;
      }
    }
  },
  mutations: {
    updateLocalStorage(state, { access, refresh }) {
      localStorage.setItem("access_token", access);
      localStorage.setItem("refresh_token", refresh);
      state.accessToken = access;
      state.refreshToken = refresh;
    },
    updateAccess(state, access) {
      localStorage.setItem("access_token", access);
      state.accessToken = access;
    },
    destroyToken(state) {
      state.accessToken = null;
      state.refreshToken = null;
    },
    updateIsSuperuser(state, isSuperuser) {
      localStorage.setItem("is_superuser", isSuperuser);
      state.isSuperuser = isSuperuser;
    }
  },
  actions: {
    // acquires a new access token if expired
    refreshToken(context) {
      return new Promise((resolve, reject) => {
        axiosBase
          .post("token/refresh/", {
            refresh: context.state.refreshToken
          }) // send current refresh token to the backend
          .then(response => {
            // if backend returns new access token, update it
            context.commit("updateAccess", response.data.access);
            // refresh isSuperuser
            userService.isSuperuser().then(response => {
              context.commit("updateIsSuperuser", response.data);
            });
            resolve(response.data.access);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    logoutUser(context) {
      if (context.getters.loggedIn) {
        return new Promise<void>((resolve, _) => {
          axiosBase
            .post("/token/logout/", {
              refresh_token: localStorage.getItem("refresh_token")
            }) // send current refresh token to the backend to invalidate it
            .then(() => {
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              context.commit("destroyToken");
              resolve();
            })
            .catch(err => {
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              context.commit("destroyToken");
              resolve(err);
            });
        });
      }
    },
    loginUser(context, credentials) {
      return new Promise<void>((resolve, reject) => {
        // send credentials to the backend
        axiosBase
          .post("/token/", {
            username: credentials.username,
            password: credentials.password
          })
          .then(response => {
            context.commit("updateLocalStorage", {
              access: response.data.access,
              refresh: response.data.refresh
            });
            // update isSuperuser
            userService.isSuperuser().then(response => {
              context.commit("updateIsSuperuser", response.data);
            });
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
});

export default store;
