

































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { photoService } from "@/services/photo.service";
import { taskService } from "@/services/task.service";
import { categoryService } from "@/services/category.service";
// eslint-disable-next-line no-unused-vars
import { PhotoCollectionEntity } from "@/entities/PhotoCollection.entity";
// eslint-disable-next-line no-unused-vars
import { CategoryEntity } from "@/entities/Category.entity";
import { questionService } from "@/services/question.service";
// eslint-disable-next-line no-unused-vars
import { QuestionEntity } from "@/entities/Question.entity";

@Component({
  components: {
    DatasetUpload: () => import("@/components/DatasetUpload.vue")
  }
})
export default class Home extends Vue {
  // Data
  taskName = "";
  taskDescription = "";
  taskType = "";
  questions: QuestionEntity[] = [];
  selectedQuestionId = 0;
  categories: CategoryEntity[] = [];
  selectedCategoriesIds: number[] = [];
  valid = false;
  rules = {
    taskName: [(v: String) => !!v || "Task name is required"],
    photoCollection: [(v: String) => !!v || "Photo collection is required"],
    categoryRules: [
      (v: String[]) => v.length > 0 || "At least one category is required"
    ],
    questionRules: [(v: String[]) => !!v || "Question is required"],
    taskType: [(v: String) => !!v || "You must choose the type of the task"]
  };
  uploadError = false;
  uploadErrorText = "";
  chooseExistingPhotoCollection = false;
  photoCollections = new Array<PhotoCollectionEntity>();
  selectedPhotoCollectionId = 0;
  taskCreated = false;
  fullProgress = false;

  // Methods
  createTask(photoCollectionId: number) {
    if (this.taskType == "visual") {
      taskService
        .addVisualTask(
          this.taskName,
          this.taskDescription,
          photoCollectionId,
          this.selectedCategoriesIds
        )
        .then(() => {
          this.resetPage();
        });
    } else {
      taskService
        .addClassificationTask(
          this.taskName,
          this.taskDescription,
          photoCollectionId,
          this.selectedQuestionId
        )
        .then(() => {
          this.resetPage();
        });
    }
  }

  resetPage() {
    this.uploadError = false;
    this.uploadErrorText = "";
    this.taskCreated = true;
    this.$router.go(0);
  }

  submit() {
    if (this.chooseExistingPhotoCollection) {
      this.createTask(this.selectedPhotoCollectionId);
    } else {
      const datasetUpload: any = this.$refs.datasetUpload;
      datasetUpload.createPhotoCollection();
    }
  }

  getQuestionStatement(question: any) {
    return question.question_statement;
  }

  getCategoryNameWithSelectionStyle(category: any) {
    const selectionStyleName =
      category.visual_selection_style == 1 ? "Polygons" : "Rectangles";

    return `${category.name} (${selectionStyleName})`;
  }

  getPhotoCollectionName(photoCollection: any) {
    return photoCollection.upload.substr(
      photoCollection.upload.lastIndexOf("/") + 1
    );
  }

  setUploadError(errorText: string) {
    this.taskCreated = false;
    this.fullProgress = false;
    this.uploadError = true;
    this.uploadErrorText = errorText;
  }

  setFullProgress() {
    this.fullProgress = true;
    this.uploadError = false;
    this.uploadErrorText = "";
  }

  // Created
  created() {
    photoService.getPhotoCollections().then(response => {
      this.photoCollections = response.data;
    });
    categoryService.getCategories().then(response => {
      this.categories = response.data;
    });
    questionService.getQuestions().then(response => {
      this.questions = response.data;
    });
  }
}
