import { ENV, API_BASE_URL } from "@/shared/env";

// @ts-ignore
export const DEV = ENV !== "production";

export const API_URL = `${API_BASE_URL}/api`;
export const FILES_PATH = `${API_URL}/media/`;

export const colors = [
  "#6371d9",
  "#47555d",
  "#4faa2a",
  "#be22b8",
  "#c77dec",
  "#781869",
  "#1b895b",
  "#2890bb",
  "#edb047",
  "#d0202b"
];
export const colorsSize = 10;
