import { VisualTaskEntity } from "@/entities/VisualTask.entity";
import { axiosBase } from "@/api/axios-base";
import store from "@/store";
import { VisualTaskWithCategoriesEntity } from "@/entities/VisualTaskWithCategories.entity";
import { ClassificationTaskEntity } from "@/entities/ClassificationTaskEntity";
import { ClassificationTaskWithQuestionEntity } from "@/entities/ClassificationTaskWithQuestion.entity";

class TaskService {
  /** Visual Tasks */

  getVisualTask(id: number) {
    return axiosBase.get<VisualTaskEntity>(`/visual-task/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  getVisualTaskWithCategories(id: number) {
    return axiosBase.get<VisualTaskWithCategoriesEntity>(
      `/task-with-categories/${id}/`,
      {
        headers: { Authorization: `Bearer ${store.state.accessToken}` }
      }
    );
  }

  getVisualTasks() {
    return axiosBase.get<VisualTaskEntity[]>(`/visual-task/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  addVisualTask(
    name: string,
    description: string,
    photo_collection_id: number,
    categories: number[]
  ) {
    const data = {
      name: name,
      description: description,
      photo_collection: photo_collection_id,
      categories: categories
    };

    return axiosBase.post(`/visual-task/`, data, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  deleteVisualTask(id: number) {
    return axiosBase.delete(`/visual-task/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  /** Classification Tasks */

  getClassificationTask(id: number) {
    return axiosBase.get<ClassificationTaskWithQuestionEntity>(
      `/classification-task-full/${id}/`,
      {
        headers: { Authorization: `Bearer ${store.state.accessToken}` }
      }
    );
  }

  getClassificationTasks() {
    return axiosBase.get<ClassificationTaskEntity[]>(`/classification-task/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  addClassificationTask(
    name: string,
    description: string,
    photo_collection_id: number,
    question_id: number
  ) {
    const data = {
      name: name,
      description: description,
      photo_collection: photo_collection_id,
      question: question_id
    };

    return axiosBase.post(`/classification-task/`, data, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  deleteClassificationTask(id: number) {
    return axiosBase.delete(`/classification-task/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }
}

// Export a singleton instance in the global namespace
export const taskService = new TaskService();
