import Vue from "vue";
import App from "./App.vue";
import VueKonva from "vue-konva";
import router from "./router";
import store from "./store";
import "@/shared/main.css";
import vuetify from "@/plugins/vuetify";
import "vuetify/dist/vuetify.min.css";
import { DEV } from "@/shared/constants";
import { VueSpinners } from "@saeris/vue-spinners";

Vue.config.productionTip = !DEV;
Vue.use(VueKonva);
Vue.use(require("vue-shortkey"), {
  prevent: ["input", "textarea", "v-text-field"]
});
Vue.use(require("vuetify"));
Vue.use(VueSpinners);

router.beforeEach((to, from, next) => {
  let handleSuperuser = (name = "") => {
    if (to.matched.some(record => record.meta.requiresSuperuser)) {
      if (!store.getters.isSuperuser) {
        next({ name: "tasks" });
      } else if (name != "") {
        next({ name: name });
      } else {
        next();
      }
    } else {
      next();
    }
  };

  // handle routes that have a meta 'requiresAuth: true'
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({ name: "login" });
    } else {
      handleSuperuser();
    }
  } else if (to.matched.some(record => record.meta.requiresLogged)) {
    // handle routes that have a meta 'requiresLogged: true'
    if (store.getters.loggedIn) {
      handleSuperuser("home");
    } else {
      handleSuperuser();
    }
  } else {
    handleSuperuser();
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
