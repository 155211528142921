import { axiosBase } from "@/api/axios-base";
import store from "@/store";

class UserService {
  isSuperuser() {
    return axiosBase.get("/is_superuser/", {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }
}

// Export a singleton instance in the global namespace
export const userService = new UserService();
