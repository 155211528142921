import { PhotoEntity } from "@/entities/Photo.entity";
import { PhotoCollectionEntity } from "@/entities/PhotoCollection.entity";
import { axiosBase, axiosFileUpload } from "@/api/axios-base";
import store from "@/store";

class PhotoService {
  getImage(path: string) {
    return axiosBase.get<Blob>(`/media/${path}`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` },
      responseType: "blob"
    });
  }

  getPhoto(id: number) {
    return axiosBase.get<PhotoEntity>(`/photo/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  getPhotoCollection(id: number) {
    return axiosBase.get<PhotoCollectionEntity>(`/photo-collection/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  deletePhotoCollection(id: number) {
    return axiosBase.delete(`/photo-collection/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  getPhotoCollections() {
    return axiosBase.get<PhotoCollectionEntity[]>(`/photo-collection/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  getThumbnailsForPhotoCollection(id: number) {
    return axiosBase.get<PhotoEntity[]>(`/thumbnail-filter/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  getThumbnailsHeightPrefixSum(id: number) {
    return axiosBase.get<number[]>(`/thumbnail-height-prefix-sum/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  getPhotosForPhotoCollection(id: number) {
    return axiosBase.get<PhotoEntity[]>(`/photo-filter/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  addPhotoCollection(
    photoCollection: File,
    onUploadProgress: (event: any) => void
  ) {
    const data = new FormData();
    data.append("upload", photoCollection);

    return axiosFileUpload.post(`/photo-collection/`, data, {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }
}

// Export a singleton instance in the global namespace
export const photoService = new PhotoService();

export function getAsBase64(src: string) {
  return new Promise(resolve => {
    photoService.getImage(src).then(response => {
      const reader = new FileReader();

      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(response.data);
    });
  });
}
