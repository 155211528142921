import axios from "axios";
import store from "../store";
import { API_URL } from "@/shared/constants";

const axiosBase = axios.create({
  baseURL: API_URL,
  headers: {
    contentType: "application/json"
  }
});

axiosBase.interceptors.response.use(
  response => {
    // Successful response is returned back to the calling service if fulfilled
    return response;
  },
  error => {
    // any error that is not due to authentication is returned back to the calling service
    if (error.response && error.response.status != 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // try refreshing and continuing with new token
    if (error.config && error.response) {
      return store
        .dispatch("refreshToken")
        .then(access => {
          const config = error.config;
          config.headers["Authorization"] = `Bearer ${access}`;

          return new Promise((resolve, reject) => {
            axios
              .request(config)
              .then(response => {
                resolve(response);
                return response;
              })
              .catch(error => {
                reject(error);
              });
          });
        })
        .catch(error => {
          Promise.reject(error);
        });
    }
  }
);

const axiosFileUpload = axios.create({
  baseURL: API_URL
});

// refresh access token before each file upload
axiosFileUpload.interceptors.request.use(async config => {
  if (config.url && config.url.endsWith("refresh")) {
    // if we are refreshing token, don't refresh it again
    return config;
  }
  await store
    .dispatch("refreshToken")
    .then(access => {
      config.headers["Authorization"] = `Bearer ${access}`;
    })
    .catch(error => {
      Promise.reject(error);
    });
  return config;
});

export { axiosBase, axiosFileUpload };
