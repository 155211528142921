import { CategoryEntity } from "@/entities/Category.entity";
import { axiosBase } from "@/api/axios-base";
import store from "@/store";

class CategoryService {
  getCategory(id: number) {
    return axiosBase.get<CategoryEntity>(`/category/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  getCategories() {
    return axiosBase.get<CategoryEntity[]>(`/category/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  getCategoriesForTask(taskId: number) {
    return axiosBase.get<CategoryEntity[]>(`/categories-for-task/${taskId}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  deleteCategory(id: number) {
    return axiosBase.delete(`/category/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  addCategory(name: string, description: string, annotationStyle: string) {
    const data = {
      name: name,
      description: description,
      visual_selection_style: annotationStyle == "Polygons" ? "1" : "2"
    };

    return axiosBase.post(`/category/`, data, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }
}

// Export a singleton instance in the global namespace
export const categoryService = new CategoryService();
