import { QuestionEntity } from "@/entities/Question.entity";
import { QuestionAnswerEntity } from "@/entities/QuestionAnswer.entity";
import { axiosBase } from "@/api/axios-base";
import store from "@/store";
import { QuestionAnswerExportEntity } from "@/entities/QuestionAnswerExport.entity";
import { QuestionChoiceEntity } from "@/entities/QuestionChoice.entity";

class QuestionService {
  getQuestions() {
    return axiosBase.get<QuestionEntity[]>(`/question/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  getQuestionsChoices() {
    return axiosBase.get<QuestionChoiceEntity[]>(`/question-choice/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  addQuestion(name: string) {
    const data = {
      question_statement: name
    };

    return axiosBase.post(`/question/`, data, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  addQuestionChoice(choice: string, questionId: number) {
    const data = {
      choice_statement: choice,
      question: questionId
    };

    return axiosBase.post(`/question-choice/`, data, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  deleteQuestion(id: number) {
    return axiosBase.delete(`/question/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  getAnswerForTaskAndPhoto(task_id: number, photo_id: number) {
    return axiosBase.get<QuestionAnswerEntity>(
      `/answer-for-task-and-photo/${task_id}/${photo_id}/`,
      {
        headers: { Authorization: `Bearer ${store.state.accessToken}` }
      }
    );
  }

  getAnswersFor(taskId: number) {
    return axiosBase.get<QuestionAnswerEntity[]>(
      `/answers-for-task/${taskId}/`,
      {
        headers: { Authorization: `Bearer ${store.state.accessToken}` }
      }
    );
  }

  addAnswer(task_id: number, photo_id: number, choice_id: number, comment: string) {
    const data = {
      task: task_id,
      photo: photo_id,
      choice: choice_id,
      comment: comment
    };

    return axiosBase.post(`/question-answer/`, data, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  updateAnswer(answer: QuestionAnswerEntity) {
    const data = {
      task: answer.task,
      photo: answer.photo,
      choice: answer.choice,
      comment: answer.comment
    };

    return axiosBase.put(`/question-answer/${answer.id}/`, data, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  getExportAnswersForTask(id: number) {
    return axiosBase.get<QuestionAnswerExportEntity[]>(
      `/export-classification-task/${id}/`,
      {
        headers: { Authorization: `Bearer ${store.state.accessToken}` }
      }
    );
  }
}

// Export a singleton instance in the global namespace
export const questionService = new QuestionService();
